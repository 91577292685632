import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import Header from "../components/Header";
import Content from "../components/Content";
import MdxProvider from "../components/ui/MdxProvider";
import Footer from "../components/Footer";
import { SEO } from "../components/SEO";

type ProjectProps = {
  data: any;
};

type Project = {
  title: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    featuredImage: ImageDataLike;
    type: string;
    title: string;
  };
};

export default function Project({ data }: ProjectProps) {
  const projects: Project[] = data.allMdx.edges.map(({ node }: any) => node);
  return (
    <MdxProvider>
      <Header />
      <Content light>
        <div className="divide-y-2 divide-gray-200">
          <div>
            <h2 className="font-serif text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Projects
            </h2>
            <div className="mt-3 sm:mt-4 lg:items-center">
              <p className="text-xl text-gray-500">
                Learn more about the amazing homes we&apos;ve built. We provide
                superior results and an honest and straight-forward perspective
                for every project we do. Like what you see?
              </p>
            </div>
          </div>
          <div className="mx-auto mt-12 grid max-w-lg gap-5 pt-12 lg:max-w-none lg:grid-cols-2">
            {projects.map((project) => (
              <Link
                to={`/projects/${project.fields.slug}`}
                key={project.title}
                className="flex flex-col overflow-hidden rounded-lg shadow-lg"
              >
                <div className="flex-shrink-0 flex-grow">
                  <GatsbyImage
                    className="max-h-96"
                    image={getImage(project.frontmatter.featuredImage)!}
                    alt=""
                  />
                </div>
                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                  <div className="flex-1">
                    <p className="text-sm font-medium uppercase text-primary-600">
                      {project.frontmatter.type}
                    </p>
                    <p className="font-serif text-xl font-semibold text-gray-900">
                      {project.frontmatter.title}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </Content>
      <Footer />
    </MdxProvider>
  );
}

export const Head = () => <SEO title="Projects" />;

export const query = graphql`
  query Projects {
    allMdx(
      filter: { frontmatter: { type: { eq: "project" } } }
      sort: { fields: frontmatter___sort, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            type
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 800, height: 800, placeholder: BLURRED)
              }
            }
          }
          fields {
            slug
          }
          id
        }
      }
    }
  }
`;
