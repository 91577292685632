import React from 'react';
import { MDXProvider } from '@mdx-js/react';

function MyParagraph(props: any) {
  return <p className="my-5" {...props} />;
}

const components = {
  p: MyParagraph,
};

type ProviderProps = {
    children: React.ReactNode;
}
export default function Provider({ children }: ProviderProps) {
  return <MDXProvider components={components}>{children}</MDXProvider>;
}

export const wrapRootElement = ({ element }) => (
  <Provider>{element}</Provider>
);
